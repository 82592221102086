import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Project1 = () => {
  const { t } = useTranslation();


  return (
    <Container>
      <Box mt={5}>
        <Typography variant="h4" gutterBottom>
          {t('project1.title')}
        </Typography>
        <img src="path/to/your/image1.jpg" alt="Project 1" style={{ width: '100%', height: 'auto', marginBottom: '20px' }} />
        <Typography variant="body1" paragraph>
          {t('project1.description')}
        </Typography>
      </Box>
    </Container>
  );
};

export default Project1;