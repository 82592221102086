import React from 'react';
import { Typography, Grid, Card, CardMedia, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ bgcolor: 'background.default', p: 2, mt: 'auto' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={8} md={4}>
          <Card>
            <CardMedia
              component="img"
              alt="Solar-Terrestrial Data"
              height="auto"
              image="https://www.hamqsl.com/solar101pic.php"
              title="Solar-Terrestrial Data"
              sx={{ maxHeight: 500 }}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ bgcolor: 'text.secondary', p: 2, mt: 2, textAlign: 'center' }}>
            <Typography variant="body2" color="text.primary" component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              &copy; {t('footer.text')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
