import React from 'react';
import { Typography, Grid, Card, CardContent, CardMedia, CardActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PortfolioPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {t('portfolio.title')}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component="img"
              alt={t('project1.title')}
              height="140"
              image="path/to/your/image1.jpg"
              title={t('project1.title')}
            />
            <CardContent>
              <Typography variant="h5" component="h2">
                {t('project1.title')}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {t('project1.description')}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" href="/projects/project1">
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component="img"
              alt={t('project2.title')}
              height="140"
              image="path/to/your/image2.jpg"
              title={t('project2.title')}
            />
            <CardContent>
              <Typography variant="h5" component="h2">
                {t('project2.title')}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {t('project2.description')}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" href="/projects/project2">
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PortfolioPage;
