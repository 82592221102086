import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Container, Box, List, ListItem, ListItemText } from '@mui/material';

const Project2 = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Box mt={5}>
        <Typography variant="h4" gutterBottom>
          {t('project2.title')}
        </Typography>
        <img src="path/to/your/image2.jpg" alt="Project 2" style={{ width: '100%', height: 'auto', marginBottom: '20px' }} />
        <Typography variant="body1" paragraph>
          {t('project2.description')}
        </Typography>
        <Typography variant="h5" gutterBottom>
          {t('project2.features')}
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary={t('project2.feature_list.feature1')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('project2.feature_list.feature2')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('project2.feature_list.feature3')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('project2.feature_list.feature4')} />
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default Project2;
