import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <Box mt={5} textAlign="center">
      <Typography variant="h4" gutterBottom>
        {t('maintenance.title')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('maintenance.description_fi')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('maintenance.description_en')}
      </Typography>
      <Typography variant="body2">
        {t('maintenance.signature')}
      </Typography>
    </Box>
  );
};

export default Maintenance;
