import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, FormControl, Select, MenuItem, IconButton, Menu, Link} from '@mui/material';
import { Menu as MenuIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import Flag from 'react-world-flags';
import '../App.css'; 

const Header = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(Cookies.get('language') || 'fi'); // default to 'fi' if no cookie
  const [anchorEl, setAnchorEl] = useState(null); // State for the menu anchor
  const open = Boolean(anchorEl); // Boolean for menu open state

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    Cookies.set('language', selectedLanguage);
    setLanguage(selectedLanguage);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          PaperProps={{ style: { width: '200px' } }} // Optional: Customize menu width
        >
          <MenuItem onClick={handleMenuClose}>
            <a href="/PortfolioPage" className="nav-link">{t('header.portfolio')}</a>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <a href="https://github.com/mikosavolainen" target="_blank" rel="noopener noreferrer" className="nav-link">GitHub</a>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <a href="https://www.qrz.com/db/OH3CYT" target="_blank" rel="noopener noreferrer" className="nav-link">QRZ</a>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <a href="https://www.eQSL.cc/Member.cfm?OH3CYT" target="_blank" rel="noopener noreferrer" className="nav-link">eQSL</a>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <a href="https://oh3ac.oh3cyt.com" target="_blank" rel="noopener noreferrer" className="nav-link">WebSdr</a>
          </MenuItem>
        </Menu>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
      <Link component={RouterLink} to="/" underline="none" color="inherit">
  {t('header.title')}
</Link>
      </Typography>
      {/* Other header content like navigation items */}
    
        <FormControl variant="standard" className="language-selector">
          <Select
            id="language-select"
            value={language}
            onChange={handleLanguageChange}
            className="select-language"
            MenuProps={{ PaperProps: { style: { maxHeight: 200, width: 150 } } }} // Customize dropdown height and width
            IconComponent={ExpandMoreIcon} // Use custom icon component for dropdown arrow
          >
            <MenuItem value="fi">
              <Flag country="FI" style={{ width: 24, height: 16, marginRight: 8 }} />
              Finnish
            </MenuItem>
            <MenuItem value="en">
              <Flag country="GB" style={{ width: 24, height: 16, marginRight: 8 }} />
              English
            </MenuItem>
          </Select>
        </FormControl>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
