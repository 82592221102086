// src/pages/NotFoundPage.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const NotFoundPage = () => (
  <Container>
    <Typography variant="h4" align="center">
      404 - Page Not Found
    </Typography>
    <Typography variant="body1" align="center">
      Sorry, the page you are looking for does not exist.
    </Typography>
  </Container>
);

export default NotFoundPage;
