import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CssBaseline, Container } from '@mui/material';
import ReactGA from 'react-ga';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Maintenance from './pages/Maintenance';
import Short_Maintenance from './pages/Short_Maintenance';
import PortfolioPage from './pages/PortfolioPage';
import Project1 from './pages/projects/Project1';
import Project2 from './pages/projects/Project2';
import NotFoundPage from './pages/NotFoundPage';  // Import the 404 page
import './i18n'; 

const trackingId = 'G-B2GD7H6D8B'; 

ReactGA.initialize(trackingId);

const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div>
      <CssBaseline />
      <Header />
      <Container>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/Short_Maintenance" element={<Short_Maintenance />} />
          <Route path="/PortfolioPage" element={<PortfolioPage />} />
          <Route path="/projects/project1" element={<Project1 />} />
          <Route path="/projects/project2" element={<Project2 />} />
          <Route path="*" element={<NotFoundPage />} />  {/* Catch-all route for 404 */}
        </Routes>
      </Container>
      <Footer />
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
