import React from 'react';
import { Typography, Grid, Box, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  const renderEquipmentList = (items) => (
    <ul>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {t('home.title')}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography variant="h6">{t('home.equipment.title')}</Typography>
            <Typography variant="body1">{t('home.equipment.handheld')}</Typography>
            {renderEquipmentList(t('home.equipment.handheld_list', { returnObjects: true }))}
            <Typography variant="body1">{t('home.equipment.mobile')}</Typography>
            {renderEquipmentList(t('home.equipment.mobile_list', { returnObjects: true }))}
            <Typography variant="body1">{t('home.equipment.hf')}</Typography>
            {renderEquipmentList(t('home.equipment.hf_list', { returnObjects: true }))}
            <Typography variant="body1">{t('home.equipment.antennas')}</Typography>
            {renderEquipmentList(t('home.equipment.antennas_list', { returnObjects: true }))}
            <Typography variant="body1">{t('home.equipment.other')}</Typography>
            {renderEquipmentList(t('home.equipment.other_list', { returnObjects: true }))}
          </Box>
        </Grid>
      </Grid>
      <Typography variant="h5" gutterBottom>
        {t('gallery')}
      </Typography>
      <ImageList cols={3} gap={8}>
        <ImageListItem>
          <img
            src="photos/OH3CYT_ON4BN_FT8.jpg"
            alt={t('gallery_images.image1.alt')}
            loading="lazy"
          />
          <ImageListItemBar
            title={t('gallery_images.image1.title')}
            subtitle={t('gallery_images.image1.description')}
            position="below"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src="photos/kuva2.jpg"
            alt={t('gallery_images.image2.alt')}
            loading="lazy"
          />
          <ImageListItemBar
            title={t('gallery_images.image2.title')}
            subtitle={t('gallery_images.image2.description')}
            position="below"
          />
        </ImageListItem>
        {/* Add more ImageListItem components as needed */}
      </ImageList>
      <Typography variant="h5" gutterBottom>
        {t('home.last_contacts')}
      </Typography>
      <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
        <iframe
          title="Last contacts"
          src="https://logbook.qrz.com/lbstat/OH3CYT/"
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          frameBorder="0"
        />
      </div>
    </>
  );
};

export default Home;
